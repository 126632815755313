export const API_URL = 'https://dev.stt-user.acuizen.com';
export const MAIN_URL = 'https://dev.stt.acuizen.com'
// export const API_URL = 'http://localhost:3060';
export const ALL_LOCATION1_URL = API_URL + '/all-location-ones';
export const QR_OBSERVATION_REPORT_URL = API_URL + '/qr-observation-reports';

export const QR_ACTIONS_WITH_ID = (id) => {
    return API_URL + '/qr-actions/' + id;
}

export const QR_OBSERVATION_REPORT_URL_WITH_ID = (id) => {
    return API_URL + '/qr-observation-reports/' + id;
}

export const LOCATION_TIER1_URL = (id) => {
    return API_URL + '/location-ones/' + id + '/location-twos';
}

export const TIER1_TIER2_URL = (id) => {
    return API_URL + '/location-twos/' + id + '/location-threes';
}

export const TIER2_TIER3_URL = (id) => {
    return API_URL + '/location-threes/' + id + '/location-fours';
}

export const TIER3_TIER4_URL = (id) => {
    return API_URL + '/location-fours/' + id + '/location-fives';
}
export const TIER4_TIER5_URL = (id) => {
    return API_URL + '/location-fives/' + id + '/location-sixes';
}

export const LOCATION1_URL = API_URL + '/location-ones';
export const LOCATION2_URL = API_URL + '/location-twos';
export const LOCATION3_URL = API_URL + '/location-threes';
export const LOCATION4_URL = API_URL + '/location-fours';
export const LOCATION5_URL = API_URL + '/location-fives';
export const LOCATION6_URL = API_URL + '/location-sixes';

export const WORK_ACTIVITIES_URL = API_URL + '/work-activities';
export const GET_USERS_BY_ROLE = API_URL + '/users/get_users';

export const HAZARD_CATEGORY = MAIN_URL + '/hazard-categories';

export const HAZARD_TYPE_CATEGORY_ID = (id) => {
    return MAIN_URL + '/hazard-categories/' + id + '/hazard-types';
} 

export const HAZARD_DESC_TYPE_ID = (id) => {
    return MAIN_URL + '/hazard-types/' + id + '/hazard-descriptions';
} 