import React, { useState, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { LOCATION1_URL, LOCATION2_URL, QR_ACTIONS_WITH_ID, LOCATION3_URL, GET_USERS_BY_ROLE, LOCATION4_URL, WORK_ACTIVITIES_URL, TIER3_TIER4_URL, TIER4_TIER5_URL, ALL_LOCATION1_URL, QR_OBSERVATION_REPORT_URL_WITH_ID } from '../constants';
import API from '../services/api';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import moment from 'moment';

const Action = () => {
    const [actionTaken, setActionTaken] = useState("");
    const [uploads, setUploads] = useState([]);
    const [obs, setObs] = useState(null)
    const [action, setAction] = useState(null)
    const [actionAssignedTo, setActionAssignedTo] = useState('');
    const [actionAssignee, setActionAssignee] = useState([])
    const { id, mode } = useParams();
    const [hideForm, setHideForm] = useState(false)
    const [remarks, setRemarks] = useState('')
    const [qrLink, setQrLink] = useState('')
    useEffect(() => {
        if (id)
            getActions(id)
    }, [id])

    const getActions = async (id) => {
        const response = await API.get(QR_ACTIONS_WITH_ID(id));
        if (response.status === 200) {
            setAction(response.data.action)
            setObs(response.data.obs)
            getActionAssignee(response.data.obs.locationOneId, response.data.obs.locationTwoId, response.data.obs.locationThreeId, response.data.obs.locationFourId)
        }

    }
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploads([...uploads, ...acceptedFiles]);
        }
    });

    const isImageFile = (fileName) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const fileExtension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(fileExtension);
    };



    const getActionAssignee = async (selectedCountry, selectedCity, selectedBusinessUnit, selectedProjectData) => {
        try {
            const response = await API.post(GET_USERS_BY_ROLE, {
                locationOneId: selectedCountry,
                locationTwoId: selectedCity,
                locationThreeId: selectedBusinessUnit,
                locationFourId: selectedProjectData,
                mode: 'obs-action-reviewer'
            });

            if (response.status === 200) {
                setActionAssignee(response.data.map((activity) => ({
                    label: activity.firstName, // Assuming the response contains "name" as the key
                    value: activity.id,   // Assuming the response contains "id" as the key
                })));

            } else {
                console.error(`Error: Received status code ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching inspector data:', error);
        }
    };

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!actionTaken) {
            alert('Action Take Field is Mandatory')
        } else {


            let signUrl = []

            try {

                for (const file of uploads) {
                    const formData = new FormData();
                    formData.append('file', file);

                    // Upload each file
                    const fileResponse = await API.post('/files', formData, config);
                    if (fileResponse.status === 200) {
                        signUrl.push(fileResponse.data.files[0].originalname);
                    } else {
                        console.error(`File upload failed for ${file.name}:`, fileResponse.statusText);
                    }
                }
            }
            catch (e) {
                console.log(e)
            }

            const reportData = {
                actionType: 'reviewer',
                actionToBeTaken: action.actionToBeTaken,
                actionTaken: actionTaken,

                uploads: signUrl,
                remarks: remarks,
                comments: remarks,

                dueDate: action.dueDate,
                assignedToId: actionAssignedTo,
                description: action.description,
                objectId: action.objectId,
                status: 'Open',


            };

            // Submit data to API
            try {
                const response = await API.patch(QR_ACTIONS_WITH_ID(id), reportData);
                if (response.status === 204) {
                    alert('Submitted!')
                    setHideForm(true)
                }

            } catch (error) {
                console.error('Error submitting report:', error);
            }
        }
    };

    if (!obs || mode !== obs.qrLink) {
        return (
            <div className="container publicObs-form mt-4">
                <h2 className='text-center mb-4'>Thank You!</h2>
                <p className='text-center'>Already Submitted</p>
            </div>
        );
    }


    return (

        <div className="container mt-4">
            <h3 className="text-center mb-4">Observation Details</h3>
            {/* {console.log(obs.qrLink)} */}
            {hideForm && <h2 className='text-center mb-4'>Action Submitted!</h2>}
            {(obs && action && !hideForm) &&

                <>


                    <div className="card p-3 mb-3">
                        <h4>Location</h4>
                        <p>{obs.locationOne.name} &gt; {obs.locationTwo.name} &gt; {obs.locationThree.name} &gt; {obs.locationFour.name} &gt; {obs.locationFive.name} &gt; {obs.locationSix.name}</p>
                        <p><strong>Created:</strong> {moment(obs.created).format("Do MMM YYYY")}</p>
                        <p><strong>QR Role:</strong> {obs.qrRole}</p>
                    </div>

                    <div className="card p-3 mb-3">
                        <h4>Description</h4>
                        <p>{obs.describeSafekObservation || obs.describeAtRiskObservation || obs.describeActionTaken || "No description provided"}</p>
                    </div>

                    {/* <div className="card p-3 mb-3">
                        <h4>General Condition</h4>
                        <p>{obs.generalCondition || "No General Condition provided"}</p>
                    </div> */}

                    <div className="card p-3 mb-3">
                        <h4>Category</h4>
                        <p>{obs.category} ({obs.conditionAct ?? ''})</p>
                        <p><strong>Rectified on the Spot:</strong> {obs.rectifiedStatus}</p>
                    </div>

                    <div className="card p-3 mb-3">
                        <h4>Hazard Category</h4>
                        <p>{obs.hazardCategory?.name || "No Hazard Category provided"}</p>
                    </div>
                    {/* <div className="card p-3 mb-3">
                        <h4>hazard Type</h4>
                        <p>{obs.hazardType?.name || "No Hazard Type provided"}</p>
                    </div> */}
                    <div className="card p-3 mb-3">
                        <h4>Hazard Description</h4>
                        <p>{obs.hazardDescription?.name || "No Hazard Description provided"}</p>
                    </div>

                    <div className="card p-3 mb-3">
                        <h4>Uploads</h4>
                        {obs.uploads.length > 0 ? (
                            <ul className="list-unstyled">
                                {obs.uploads.map((fileName, index) => (
                                    <li key={index} className="mb-3">
                                        {isImageFile(fileName) ? (
                                            <img
                                                src={`https://dev.stt-user.acuizen.com/docs/${fileName}`}
                                                alt={`Upload ${index + 1}`}
                                                style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px' }}
                                            />
                                        ) : (
                                            <a
                                                href={`https://dev.stt-user.acuizen.com/docs/${fileName}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                View File {index + 1}
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No uploads available</p>
                        )}
                    </div>

                    <div className="card p-3 mb-3">
                        <h4>Action Details</h4>
                        <p><strong>Description:</strong> {action.description || "No action description provided"}</p>
                        {obs.remarks && <p><strong>Comments from Reviewer:</strong> {obs.remarks ?? ''}</p>}
                        <p><strong>Action To Be Taken:</strong> {action.actionToBeTaken}</p>
                        <p><strong>Due Date:</strong> {moment(action.dueDate).format("Do MMM YYYY")}</p>
                        <div className="mt-3">
                            <h5>Action Taken</h5>
                            <InputTextarea
                                value={actionTaken}
                                onChange={(e) => setActionTaken(e.target.value)}
                                rows={3}
                                className="w-100"
                                placeholder="Enter action taken"
                            />
                        </div>

                        {/* <div className="mt-3">
                            <h5>Remarks</h5>
                            <InputTextarea
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                                rows={3}
                                className="w-100"
                                placeholder="Enter Remarks"
                            />
                        </div> */}
                    </div>

                    <div className="card p-3 mb-3">
                        <h4>Upload New Files</h4>
                        <div
                            {...getRootProps()}
                            className="border border-primary p-5 text-center"
                            style={{ cursor: 'pointer', borderRadius: '5px' }}
                        >
                            <input {...getInputProps()} />
                            <p className="text-muted">Click to select files</p>
                        </div>
                        {uploads.length > 0 && (
                            <ul className="mt-3">
                                {uploads.map((file, index) => (
                                    <li key={index}>
                                        {file.preview ? (
                                            <img
                                                src={file.preview}
                                                alt={file.name}
                                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'contain' }}
                                            />
                                        ) : (
                                            <a href={URL.createObjectURL(file)} download={file.name}>
                                                {file.name}
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="card p-3 mb-3">
                        <h4>Select Reviewer</h4>


                        <Dropdown className="w-100" id="actionAssignedTo" value={actionAssignedTo} options={actionAssignee} onChange={(e) => setActionAssignedTo(e.value)} optionLabel="label" placeholder="Select" />


                    </div>

                    <Button label="Submit" className="p-button-danger w-100 mt-4" onClick={handleSubmit} />
                </>
            }
        </div>



    );
};

export default Action;
